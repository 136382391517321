import apiAuth from '@/api/auth'

export default {
	name: 'AdminAuth',
	data () {
		return {
			valid: false,
			showPass: false,
			passwordRules: [
				v => !!v || 'Password is required',
				v => v.length >= 6 || 'Password must be more than 6 characters'
			],
			emailRules: [
				v => !!v || 'E-mail is required',
				v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
			],
			form: {
				email: '',
				password: ''
			}
		}
	},
	methods: {
		login () {
			apiAuth.login(this)
		}
	}
}
