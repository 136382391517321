import ApiBase from '@/api/base'
import localStore from '@/helpers/localStorageHelper'
import {notify} from "@/helpers/notifyHelper";
import ApiBatch from '@/api/batch'

class Auth {
	static login(self) {
		const form = self.form
		self.$refs.form.validate()
		self.http('post', `${ApiBase.baseUrl()}/users/login`, form)
		.then(response => {
			localStore.set('Token', response.data.token)
			notify(self, 'success', 'logged in')
			ApiBatch.getAccountInfo(self)
			self.$router.push({name: 'ContactSubmittingList'})
		})
			.catch(err => {
				console.log(err)
				notify(self, 'error', err)
			})
	}
	static logout() {
		ApiBase.unauthoriseUser('/')
	}
}

export default Auth
